$footer-height: 40px;

$header-height-small: 220px;

$header-width-medium: 575px;
$header-height-medium: 260px;
$header-border-width-medium: 10px;
$header-corner-radius-medium: 25px;
$body-width-medium: 450px;

$header-width-large: 850px;
$header-height-large: 340px;
$header-border-width-large: 20px;
$header-corner-radius-large: 50px;
$body-width-large: 650px;

$header-text-block-width: 750px;
$header-text-block-height: 280px;

$color-header-background: #efe7df;
$color-first-name: #b96772;
$color-last-name: rgb(161, 180, 207);
$color-default-text: #505050;
$color-body-background: white;

$color-btn-primary: #6787b5;
$color-btn-primary-hover: #5fa9ea;

$color-btn-secondary: #B58484;
$color-btn-secondary-hover: rgb(219, 152, 161);

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
