@media print {
  @page {
    size: A4 portrait;
  }
  body {
    font-size: 0.5cm;
    color: black;
  }

  html {
    width: 8.5in !important;
    height: 11in !important;
  }
}
body {
  color: #505050;
}

a, a:visited, a:hover, a:link {
  color: #505050;
}

.title {
  margin-bottom: 1em;
  font-family: "Oleo Script", cursive;
  width: 100%;
  background: linear-gradient(#ab7878, #d6aaaa);
  color: white;
  padding-top: 0.5em;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media not print and (max-width: 576px) {
  .title {
    font-size: 0.7em;
  }
}

button.btn {
  border-radius: 100px;
  width: 7em;
}
@media not print and (max-width: 576px) {
  button.btn {
    font-size: 0.8em;
  }
}

button.btn-primary {
  background-color: #6787b5;
  border-color: #6787b5;
}

button.btn-primary:hover {
  background-color: #5fa9ea;
  border-color: #5fa9ea;
}

button.btn-secondary {
  background-color: #B58484;
  border-color: #B58484;
}

button.btn-secondary:hover {
  background-color: #db98a1;
  border-color: #db98a1;
}