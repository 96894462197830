@import "assets/scss/variables.scss";
@import "assets/scss/mixins.scss";

@media print {
  @page {
    size: A4 portrait;
  }

  body {
    font-size: 0.5cm;
    color: black;
  }

  html {
    width: 8.5in !important;
    height: 11in !important;
  }
}

body {
  color: $color-default-text;
}

a, a:visited, a:hover, a:link {
  color: $color-default-text;
}

.title {
  margin-bottom: 1em;
  font-family: 'Oleo Script', cursive;
  width: 100%;
  background: linear-gradient(#ab7878, #d6aaaa);
  color: white;
  padding-top: 0.5em;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include xs {
    font-size: 0.7em;
  }
}

button.btn {
  border-radius: 100px;
  width: 7em;

  @include xs {
    font-size: 0.8em;
  }
}

button.btn-primary {
  background-color: $color-btn-primary;
  border-color: $color-btn-primary;
}

button.btn-primary:hover {
  background-color: $color-btn-primary-hover;
  border-color: $color-btn-primary-hover;
}


button.btn-secondary {
  background-color: $color-btn-secondary;
  border-color: $color-btn-secondary;
}

button.btn-secondary:hover {
  background-color: $color-btn-secondary-hover;
  border-color: $color-btn-secondary-hover;
}
