
@import "./variables.scss";

@mixin print {
  @media print {
    @content;
  }
}

@mixin xs {
  @media not print and (max-width: #{$screen-sm-min}) {
      @content;
  }
}

@mixin sm {
  @media not print and (min-width: #{$screen-sm-min}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}

@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
      @content;
  }
}
